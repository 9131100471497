
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch
    gap: 8px

.left
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  margin-top: 80px
  margin-bottom: 32px
  +bpw(lg)
    text-align: left
    display: flex
    border-radius: 8px
    width: 30%
    overflow: hidden
    align-items: center
    justify-content: center
    margin-bottom: 0px
    margin-top: 0px
    &:before
      content: ''
      position: absolute
      left: 50%
      bottom: 0px
      right: 0px
      height: 100px
      border-radius: 8px
      background-color: $color-beige

h1
  color: $color-grey-warm-dark

.right
  position: relative
  width: 100%
  min-height: 420px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    width: 70%

.claim
  +bpw(lg)
    display: none

.action
  margin-top: 32px

.center
  max-width: 400px
  margin: 10px 20px
  +bpw(lg)
    width: 100%

.copy
  display: none
  +bpw(lg)
    display: block

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

