
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch

.description
  position: absolute
  bottom: 0
  background-color: rgba($color-grey-dark, 0.75)
  width: 100%
  height: 260px
  z-index: 10
  padding: 30px
  box-sizing: border-box
  h2, p
    vertical-align: top
    color: white
    display: inline-block
  h2
    padding-right: 5%
    max-width: 30%
  p
    max-width: 60%

.left
  position: relative
  width: 100%
  min-height: 420px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    width: 60%
    display: flex

.right
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  margin-top: 32px
  margin-bottom: 52px
  +bpw(lg)
    margin-top: 0
    margin-bottom: 0
    text-align: left
    width: 40%
  p
    color: $color-grey-dark
  h1
    color: $color-grey-dark
    font-size: 1.5em

.claim
  +bpw(lg)
    display: none

.action
  margin-top: 32px

.center
  max-width: 400px
  margin: 10px 20px
  +bpw(lg)
    width: 100%

.copy
  display: none
  +bpw(lg)
    display: block

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

