
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%
  +bpw(lg)
    width: 70%


.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    min-height: 100vH
    flex-direction: row
    align-items: stretch

.description
  position: absolute
  bottom: 0
  background-color: rgba($color-grey-dark, 0.795)
  width: 100%
  height: 300px
  z-index: 10
  padding: 20px
  box-sizing: border-box
  font-size: 0.8em
  transition: transform 0.5s ease
  h2, p
    vertical-align: top
    color: white
  h2
    margin-bottom: 16px
  p
    font-size: 1em
    line-height: 1.7
  +bpw(lg)
    height: 260px
    z-index: 10
    padding: 30px
    font-size: inherit
    h2, p
      display: inline-block
      vertical-align: top
      color: white
    h2
      padding-right: 5%
      max-width: 30%
      margin-bottom: 0px
    p
      max-width: 70%
  +bpw(xl)
    p
      max-width: 60%

.left
  position: relative
  width: 100%
  height: 700px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    height: auto
    width: 100%
    display: flex

.right
  position: relative
  height: 50px
  &:before
    content: ''
    position: absolute
    top: 8px
    left: 0px
    bottom: 0px
    right: 0px
    border-radius: 8px
    background-color: $color-grey
  +bpw(lg)
    display: none

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

