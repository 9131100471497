
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch
    gap: 8px

.description
  position: absolute
  bottom: 0
  background-color: rgba($color-grey-dark, 0.75)
  width: 100%
  height: 300px
  z-index: 10
  padding: 20px
  box-sizing: border-box
  font-size: 0.8em
  transform: translateY(217px)
  +bpw(md)
    transform: translateY(0px)
    height: 180px
    padding: 30px
  transition: transform 0.5s ease
  &.active
    transform: translateY(0px)
  h2, p
    vertical-align: top
    color: white
  h2
    margin-bottom: 16px
  p
    font-size: 1em
    line-height: 1.7
  +bpw(lg)
    transform: translateY(0px)
    height: 260px
    padding: 30px
    bottom: 0
    font-size: inherit
    h2, p
      display: inline-block
    h2
      padding-right: 5%
      max-width: 30%
      margin-bottom: 0px
    p
      max-width: 70%
  +bpw(xl)
    p
      max-width: 60%

.left
  position: relative
  width: 100%
  min-height: 370px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    display: flex
    width: 70%
    flex-direction: row

.right
  position: relative
  width: 100%
  padding-bottom: 64px
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  +bpw(lg)
    text-align: left
    width: 30%
    display: flex
    align-items: center
    justify-content: center
  p
    color: $color-grey-dark
  h1
    color: $color-grey-dark
    font-size: 1.5em

.claim
  +bpw(lg)
    display: none

.action
  margin-top: 32px

.center
  margin: 20px 20px
  max-width: 400px
  +bpw(lg)
    margin: 20px 20px
    width: 100%

.copy
  display: none
  +bpw(lg)
    display: block

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

