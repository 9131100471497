
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%
  +bpw(lg)
    width: 40%


.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch

.description
  position: absolute
  bottom: 0
  background-color: rgba($color-bronze-dark, 0.75)
  width: 100%
  height: 260px
  z-index: 10
  padding: 30px
  box-sizing: border-box
  h2, p
    vertical-align: top
    color: white
    display: inline-block
  h2
    padding-right: 5%
    max-width: 30%
  p
    max-width: 60%


.left
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  min-height: 700px
  p
    color: $color-grey-dark
  h1
    color: $color-grey-dark
  &:after
    content: ''
    position: absolute
    background-color: rgba($color-bronze, 0.35)
    height: 150px
    width: 150px
    border-radius: 8px
    right: 0px
    bottom: 0
  +bpw(lg)
    text-align: left
    margin-top: 0px
    margin-bottom: 0px
    width: 100%
    &:after
      height: 200px


.center
  max-width: 400px
  margin: 0px 45px
  +bpw(lg)
    width: 100%

.copy
  display: none
  +bpw(lg)
    display: block

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

