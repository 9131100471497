
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch

.before, .after
  position: relative
  height: 150px
  border-radius: 8px
  +bpw(lg)
    display: none
.before
  background-color: $color-grey-light
  margin-bottom: 8px
.after
  background-color: $color-grey-warm-dark
  height: 250px
  margin-top: 8px

.right
  position: relative
  width: 100%
  padding-bottom: 52.25%
  border-radius: 8px
  overflow: hidden
  +bpw(lg)

.hotspot-wrapper
  position: absolute
  left: 50%
  top: 50%
  width: 100%
  height: 100%
  transform: translateX(-50%) translateY(-50%)
  img
    width: auto
    height: 100%
    min-height: 100%
    opacity: 0
  &.portrait
   img
     width: 100%
     height: auto
  .spot
    position: absolute
    .dot
      position: absolute
      transform: translateX(-50%) translateY(-50%)
      width: 20px
      height: 20px
      +bpw(lg)
        width: 30px
        height: 30px
      box-sizing: border-box
      background-color: $color-bronze-dark
      border: 3px solid white
      border-radius: 30px
      cursor: pointer
      animation-name: pulse
      animation-duration: 1s
      animation-iteration-count: infinite
    .marker
      position: absolute
      top: 0
      transform: translateX(-50%) translateY(15px)
      &:after
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%) translateY(-90%)
        content: ''
        width: 0
        height: 0
        border-left: 7px solid transparent
        border-right: 7px solid transparent
        border-bottom: 7px solid $color-bronze-dark
      span
        display: inline-block
        background-color: $color-bronze-dark
        color: white
        padding: 6px 8px
        border-radius: 4px
        font-family: $font-main
        color: white
        text-align: center
        &:last-child
          background-color: rgba($color-bronze-dark, 0.9)


@keyframes pulse
  0%
    transform: translateX(-50%) translateY(-50%) scale(1)
  50%
    transform: translateX(-50%) translateY(-50%) scale(0.75)
  100%
    transform: translateX(-50%) translateY(-50%) scale(1)

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.fade-enter-active
  animation: go 0.75s

@keyframes go
  from
    opacity: 0
    transform: translateX(-50%) translateY(20px)
  to
    opacity: 1
    transform: translateX(-50%) translateY(15px)

