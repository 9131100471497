
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%
  box-sizing: border-box
  padding-left: 16px
  padding-right: 16px
  +bpw(lg)
    width: 33.33%
    padding-left: 24px
    padding-right: 24px
  +bpw(xl)
    width: 25%
    padding-left: 24px
    padding-right: 24px

.slide-container
  flex: 1
  display: flex
  height: 100%
  flex-direction: column

.description
  width: 100%
  min-height: 200px
  box-sizing: border-box
  h2, p
    vertical-align: top
    display: inline-block
    +bpw(md)
      display: block
    +bpw(lg)
      display: inline-block
  h2
    margin-top: 64px
    margin-bottom: 32px

.image
  flex: 1
  width: 100%
  position: relative
  overflow: hidden
  min-height: 450px
  +bpw(lg)
    min-height: auto


.action
  text-align: center
  width: 100%
  position: absolute
  bottom: 16px
  +bpw(lg)
    bottom: 16px
  +bpw(xl)
    bottom: 64px

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

