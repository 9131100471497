
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  background-position: bottom left
  background-size: contain
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch

h2, p
  color: $color-grey-dark

p
  margin-bottom: 16px

h2
  margin: 0
  margin-top: 16px
  margin-bottom: 16px
  +bpw(md)
    margin-top: 16px
    margin-bottom: 22px
  +bpw(xl)
    margin-top: 16px
    margin-bottom: 32px

.left
  position: relative
  width: 100%
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  text-align: center
  flex: 1
  border-radius: 8px
  overflow: hidden
  margin-top: 80px
  margin-bottom: 32px
  +bpw(lg)
    text-align: left
    width: 50%
    margin-top: 0px
    margin-bottom: 0px
  +bpw(hg)
    width: 35%

.right
  position: relative
  width: 100%
  min-height: 370px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    width: 35%
  +bpw(hg)
    width: 50%

.middle
  display: none
  +bpw(lg)
    overflow: hidden
    min-height: 100px
    display: flex
    justify-content: center
    flex-direction: column
    height: auto
    padding: 16px
    width: 15%
  +bpw(hg)
    width: 10%

.action
  margin-top: 32px

.center
  z-index: 10
  max-width: 400px
  margin: 10px 20px
  padding: 0 30px
  +bpw(lg)
    width: 100%

.thumb
  position: relative
  padding: 16px

.caption
  position: absolute
  top: 12px
  left: 12px
  font-size: 12px
  padding: 8px
  padding-right: 12px
  font-family: $font-main
  text-transform: uppercase
  +bpw(lg)
    background: rgba($color-grey, 0.5)
    font-size: 16px
    top: 16px
    left: 16px

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

