
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.cw-slide-tab-areal
  display: flex
  position: relative
  height: 100%
  width: 100%
  align-items: center

.content
  position: relative
  width: 100%
  padding-bottom: 80%
  overflow: hidden
  +bpw(lg)
    padding-bottom: 56.25%

.dim169
  user-select: none

.hotspot-wrapper
  position: absolute
  left: 50%
  top: 50%
  width: 100%
  height: 100%
  user-select: none
  transform: translateX(-50%) translateY(-50%)
  > img
    width: auto
    height: 100%
    min-height: 100%
    opacity: 0
    user-select: none
  &.portrait
   img
     width: 100%
     height: auto
     user-select: none
  .spot
    position: absolute
    .dot
      position: absolute
      transform: translateX(-50%) translateY(-50%)
      width: 30px
      height: 30px
      box-sizing: border-box
      background-color: $color-yellow
      border: 3px solid white
      border-radius: 30px
      cursor: pointer
      animation-name: pulse
      animation-duration: 1s
      animation-iteration-count: infinite
    .marker
      position: absolute
      text-align: center
      top: 0
      transform: translateX(-50%) translateY(15px)
      &:after
        position: absolute
        top: 0
        left: 50%
        transform: translateX(-50%) translateY(-90%)
        content: ''
        width: 0
        height: 0
        border-left: 14px solid transparent
        border-right: 14px solid transparent
        border-bottom: 14px solid #ffffff
      img
        width: 100px
        height: 100px
        border-radius: 50px
        margin: 0 50px
        margin-bottom: -25px
        +bpw(lg)
          width: 200px
          height: 200px
          border-radius: 100px
          margin: 0 50px
          margin-bottom: -25px
        border: 6px solid white
      .placeholder
        width: 300px
        height: 10px
      span
        margin-top: 0px
        background-color: $color-yellow
        color: white
        border-radius: 0px
        font-family: $font-main
        text-align: center
        display: inline
        line-height: 25px
        padding: 0 0.5rem
        box-decoration-break: clone
        font-weight: bold
      &.active
        z-index: 1000
      &.bottom
        transform: translateX(-50%) translateY(-110%)
        &:after
          top: auto
          bottom: -25px
          border-left: 14px solid transparent
          border-right: 14px solid transparent
          border-bottom: none
          border-top: 14px solid #ffffff


@keyframes pulse
  0%
    transform: translateX(-50%) translateY(-50%) scale(1)
  50%
    transform: translateX(-50%) translateY(-50%) scale(0.75)
  100%
    transform: translateX(-50%) translateY(-50%) scale(1)

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.fade-enter-active
  animation: go 0.75s

@keyframes go
  from
    opacity: 0
    margin-top: -10px
  to
    opacity: 1
    margin-top: 0px

