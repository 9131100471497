
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  padding-top: 32px
  +bpw(lg)
    padding-top: 54px

.description
  margin: 64px 25px
  max-width: 100%
  +bpw(lg)
    margin: 0
    margin-top: 42px
    max-width: 80%


//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

.topline, h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

