
@import '../../styles/variables'

.hidden
  visibility: hidden
.subtitle
  position: absolute
  left: 0
  bottom: 0
  background-color: rgba($color-bronze-dark, 0.85)
  padding: 8px 20px
  border-top-right-radius: 8px
  p
    color: white
