
@import '../../../styles/variables'
@import '../../../lib/core/sass/bp'

.swiper-slide
  width: 100%

.slide-container
  +bpw(lg)
    flex: 1
    display: flex
    height: 100%
    flex-direction: row
    align-items: stretch

.description
  position: absolute
  bottom: 0
  background-color: rgba($color-grey-dark, 0.75)
  width: 100%
  height: 300px
  z-index: 10
  padding: 20px
  box-sizing: border-box
  font-size: 0.8em
  transition: transform 0.5s ease
  h2, p
    vertical-align: top
    color: white
  h2
    margin-bottom: 16px
  p
    font-size: 1em
    line-height: 1.7
  +bpw(lg)
    width: 25%
    height: 100%
    padding: 20px 40px 20px 55px
    align-items: center
    justify-content: center
    position: relative
    display: flex
    h2
      padding-bottom: 5%
    p
      line-height: 28px
      font-size: 16px

.left
  position: relative
  width: 100%
  height: 700px
  border-radius: 8px
  overflow: hidden
  +bpw(lg)
    width: 100%
    display: flex
    height: auto

.right
  position: relative
  width: 0%

.center
  max-width: 400px
  margin: 10px 20px
  +bpw(lg)
    width: 100%

//--------------------------------------------------------------------------
// Animation Stuff
//--------------------------------------------------------------------------

h1, p
  transition: all 0.7s ease-in-out
  transform: translateY(50%)
  opacity: 0
  .animate &
    opacity: 1
    transform: translateX(0%)

p
  transform: translateY(50%)
  transition: all 0.7s ease-out 0.15s

